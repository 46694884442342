import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
        appName: "Dropnation", // Required
        //rpc: "https://rpc.ankr.com/eth_goerli", // GOERLI TESTNET
        //chainId: 5, // GOERLI TETNET
        // rpc: "https://arb1.arbitrum.io/rpc", // ARBITRUM MAINNET
        // chainId: 42161, // Optional. It defaults to 1 if not provided
        rpc: "https://rpc.pulsechain.com", // Pulsechain mainnet
        chainId: 369, // Optional. It defaults to 1 if not provided
        appLogoUrl: "", // Optional. Application logo image URL. favicon is used if unspecified
        darkMode: true, // Optional. Use dark theme, defaults to false
      },
  },
  walletconnect: {
    package: WalletConnect, // required
    options: {
        rpc: {
          // 5: "https://rpc.ankr.com/eth_goerli" GOERLI TESTNET
          // 42161: "https://arb1.arbitrum.io/rpc" // ARBITRUM MAINNET
          369: "https://rpc.pulsechain.com" //Pulsechain mainnet
        },
      },
  }
};